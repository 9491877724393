<template>
    <Header v-if="!$route.meta.magentoHeader" />
    <MagentoHeader v-else />
    <main id="main">
        <router-view />
    </main>
    <Footer />
</template>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");
@import "@/scss/_variables.scss";
@import "@/scss/_typography.scss";
@import "include-media/dist/_include-media.scss";

#main {
    padding-top: 60px;
}

.container {
    max-width: 1920px;
    padding: 0 16px;
    margin: 0 auto;

    @include media('>=tablet') {
        padding: 0 40px;
    }

    @include media('>=widescreen') {
        padding: 0 80px;
    }
}

.button {
    font-size: 14px;
    font-weight: 600;
    padding: 6px 14px;
    border: 2px solid $red;
    background: transparent;
    border-radius: 16px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    &:hover,
    &:focus {
        outline: none;
    }

    &.magento-solutions {
        display: none;

        @include media(">=tablet") {
            position: relative;
            display: inline-block;
            color: $black;
            background: $white;
            background-image: linear-gradient($darkenRed, $darkenRed);
            background-repeat: no-repeat;
            transition: background-size .3s, color .3s;
            background-size: 0% 100%;

            &:hover {
                background-size: 100% 100%;
                color: $white;
                // border:2px solid $darkRed;
            }
        }
    }

    &.go-web-mobile {
        display: none;

        @include media(">=tablet") {
            position: relative;
            display: inline-block;
            color: $black;
            background: $white;
            background-image: linear-gradient($darkenOrange, $darkenOrange);
            background-repeat: no-repeat;
            transition: background-size .3s, color .3s;
            background-size: 0% 100%;

            &:hover {
                background-size: 100% 100%;
                color: $white;
                // border:2px solid $darkOrange;
            }
        }
    }

    &.primary {
        position: relative;
        display: inline-block;
        color: $white;
        background: $red;
        background-image: linear-gradient($darkRed, $darkRed);
        background-repeat: no-repeat;
        transition: background-size .3s, color .3s;
        background-size: 0% 100%;

        &:hover {
            background-size: 100% 100%;
            // border:2px solid $darkRed;
            color: $white;
        }
    }

    &.large {
        font-size: 16px;
        padding: 10px 24px;
        border-radius: 26px;
    }

    .icon {
        margin-left: 16px;
    }

    &.magento-btn {
        position: relative;
        display: inline-block;
        color: $white;
        background: $orange;
        background-image: linear-gradient($darkenOrange, $darkenOrange);
        background-repeat: no-repeat;
        transition: background-size .3s, color .3s ease-out;
        background-size: 0% 100%;

        &:hover {
            background-size: 100% 100%;
            // border:2px solid $darkOrange;
            color: $white;
        }
    }
}

// Lazy load styles
.v-lazy-image {
    filter: blur(5px);
    transition: filter 1.6s;
    will-change: filter;

    &.v-lazy-image-loaded {
        filter: blur(0);
    }
}
</style>
<script>
import Header from "@/components/Header.vue";
import MagentoHeader from "@/components/Magento/MagentoHeader.vue";
import Footer from "@/components/Footer.vue";

export default {
    components: {
        Header,
        MagentoHeader,
        Footer
    },
    methods: {
        smoothScrollTo(hash) {
            if (!hash) return;

            const targetElement = document.querySelector(hash);
            if (targetElement) {
                window.scrollTo({
                    top: targetElement.offsetTop,
                    behavior: 'smooth'
                });
            }
        }
    },
    mounted() {
        const ref = (new URLSearchParams(window.location.search)).get('ref');
        if (ref) this.$cookies.set('ref', ref, '1y');

        // Smooth scrolling when the page is loaded
        this.smoothScrollTo(window.location.hash);

        // Add click event listeners for smooth scrolling on links
        const navLinks = document.querySelectorAll('a[href^="#"]');
        navLinks.forEach(link => {
            link.addEventListener('click', (event) => {
                event.preventDefault();
                this.smoothScrollTo(link.getAttribute('href'));
            });
        });
    }
};
</script>
