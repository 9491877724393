<template>
  <button
    class="button primary contacts-button"
    :class="size"
    :id="id"
    @click="showModal = true"
  >
    <slot><span class="button-content">
     Get in touch
      <span class="icon"
        ><img
          v-svg-inline
          :data-src="require('@/assets/icons/rarr-icon.svg')"
          alt=""
      /></span>
      </span>
    </slot>
  </button>
  <ModalContact
    v-if="showModal"
    @close="showModal = false"
    :startProjectModal="startProject"
    :moreTextGetInTouch="description"
    ><h2 v-if="type">Start a Project</h2></ModalContact
  >
</template>
<script>
import ModalContact from "./ModalContact.vue";

export default {
  components: { ModalContact },
  data() {
    return {
      showModal: false,
      startProject: this.type,
      description: this.desc,
    };
  },
  props: ["size", "type",'desc', "id"],
};
</script>
<style lang="scss">
@import "@/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.contacts-button{
  .button-content{
     display: flex;
     align-items: center;
  }
}
</style>