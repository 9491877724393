<template>
  <div class="burger-mask">
    <div class="burger-wrapper">
      <div class="burger" :class="[magento ? 'orange' : '']">
        <button class="burger-close" @click="$emit('close')" aria-label="Close">
          <img
            v-svg-inline
            :data-src="require('@/assets/icons/close-modal-menu.svg')"
            alt=""
          />
        </button>

        <div class="burger-content">
          <div class="burger-inner">
            <div class="menu-title">MENU</div>
            <HeaderMenuItems :magento="magento" @goToSection="$emit('close')" />
            <div class="buttons">
              <router-link v-if="magento" to="/" class="button"
              >Go to Web & Mobile
                <span class="icon"
                ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt="" /></span
                ></router-link>
              <router-link v-else to="/magento" class="button"
              >Magento Solutions
                <span class="icon"
                ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt="" /></span
                ></router-link>
            </div>
          </div>
          <div class="socials">
            <socials-list :socialsList="socials" whiteIcons />
             <span class="copy">
               &copy; 2022 Wdevs. All trademarks acknowledged.
             </span>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import HeaderMenuItems from "./HeaderMenuItems.vue";
import SocialsList from "@/components/SocialsList";

export default {
  components: {
    HeaderMenuItems,
    SocialsList,
  },
  data(){
    return{
      socials: [
        {
          id: "01",
          name: "linkedin",
          link: "https://www.linkedin.com/company/18265066/",
        },
        {
          id: "02",
          name: "clutch",
          link: "https://clutch.co/profile/wdevs",
        },
        {
          id: "03",
          name: "skype",
          link: "skype:alabunskiy?chat",
        },
        {
          id: "04",
          name: "telegram",
          link: "https://telegram.me/alabunskiy",
        },
      ],
    }
  },
  props: ["magento"],
};
</script>
<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.burger-mask {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

}

.burger {
  background: $red;
  border-radius: 0;
  width: 85.5vw;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  @include media("<xs-phone"){
    width: 100vw;
  }
  @include media(">phone"){
    width: 60.5vw;
  }
  #nav{
    .nav_link {
      font-size: 18px;
      @include media(">xs-phone"){
        font-size: 24px;
      }
    }
  }
  &.orange {
    background: $orange;
    #nav {
      display: flex;
      flex-direction: column;
      .nav_link {
        margin: 0px 10px;
         @include media(">xs-phone") {
           margin: 6px 16px;
        }
        &:hover, &:focus {
          color: $black;
          transition: all 0.3s ease;
        }
      }
      .dropdown {
        .nav_link {
          margin: 0;
        }
        &__content__links {
          background-color: transparent;
        }
        &:hover, &:focus {
          .nav_link {
            color: $white;
          }
        }
      }
    }
  }
  .item_links,
  .dropdown {
    padding:0px 0 0px 10px;
    @include media(">xs-phone") {
      padding:9px 0 9px 16px;
    }
    &:hover, &:focus {
      .nav_link {
        color: $black;
        opacity: 1;
      }
    }
  }
  .dropdown__content__links {
    background: $red;
    padding: 0;
    margin-left: -1px;
    &__link {
      font-size: 14px;
      color: $white;
      opacity: 1;
      margin: 16px 0 16px 0;
      padding: 0 16px 0 0;
       @include media(">xs-phone"){
          margin-bottom: 16px;
        }
      &:last-child {
        margin-bottom: 0;
        @include media(">xs-phone"){
          margin-bottom: 0;
        }
      }
      &:hover, &:focus {
        color: $black;
      }
    }
  }
  @include media(">=tablet") {
    width: 45vw;
  }
  .menu-title {
    color: $white;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    padding: 40px 0 20px 10px;
     @include media(">xs-phone") {
      padding: 60px 0 40px 16px;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding-bottom: 20px;
    .socials{
      padding:16px 0 0 16px;
      .copy{
        font-size: 12px;
        color:$white;
        margin:0;
      }
    }

    .button {
      color: $white;
      font-size:18px;
      text-decoration: none;
      font-weight: 700;
      border: none;
      padding-left: 10px;
      margin-top:5px;
      @include media(">xs-phone") {
        padding-left: 16px;
        margin-top:30px;
        font-size:24px;
      }
        @include media(">phone") {
        margin-top:48px;
      }
      .icon {
        margin-left: 10px;
        padding: 5px 0 0 10px;
        transition: all 0.3s ease-in-out;
      }
      &:hover, &:focus {
        .icon {
          margin-left: 20px;
        }
      }
    }
    .nav_link {
      margin-right: 20px;
      font-size: 14px;
      font-weight: 600;
      color: $white;
      text-decoration: none;
      transition: all 0.3s;
      text-transform: uppercase;

      @include media(">=tablet-xl") {
        margin-right: 40px;
        text-transform: none;
      }
    }
  }
  &-close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 24px;
    right: 24px;
    width: 48px;
    height: 48px;
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    @include media(">=tablet") {
      top: 24px;
      right: 24px;
    }
  }
}
</style>
