import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import CantFind from "../views/CantFind.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      metaTags: [
        {
          name: "description",
          content: "With so many IT companies out there claiming to provide the best or most unique service, it’s hard to determine who to rely on. Each IT company will attempt to describe their strengths, despite the fact that it’s providing the same service which many other companies provide too.",
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Wdevs company"
        },
        {
          property: "og:description",
          content: "With so many IT companies out there claiming to provide the best or most unique service, it’s hard to determine who to rely on. Each IT company will attempt to describe their strengths, despite the fact that it’s providing the same service which many other companies provide too."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/home-hero-image_large.0c03b750.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Wdevs company"
        },
      ],
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "About us",
      metaTags: [
        {
          name: "description",
          content: "WDevs was founded in 2007 with 3 guys who were all dedicated to their first client and relentlessly striving to reach their goals by finetuning their field of expertise, and to date the company has continued to grow stronger each step of the way - through all the ups and downs.",
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "About us"
        },
        {
          property: "og:description",
          content: "WDevs was founded in 2007 with 3 guys who were all dedicated to their first client and relentlessly striving to reach their goals by finetuning their field of expertise, and to date the company has continued to grow stronger each step of the way - through all the ups and downs."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/about"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/wdevs-logo-og-image.d29121a8.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "About us image"
        },
      ]
    },
  },
  {
    path: "/consider-outsourcing",
    name: "Outsourcing",
    component: () => import("@/views/Outsourcing.vue"),
    meta: {
      title: "Outsourcing",
      metaTags: [
        {
          name: "description",
          content: "It is natural to still believe that the development work is always something which is better to manage in-house, because when you talk to the developers personally, you feel that you have more control over the work they do.",
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Outsourcing"
        },
        {
          property: "og:description",
          content: "It is natural to still believe that the development work is always something which is better to manage in-house, because when you talk to the developers personally, you feel that you have more control over the work they do."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/consider-outsourcing"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/outsorsing-banner-bg.636b8730.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Outsourcing image"
        },
      ]
    },
  },
  {
    path: "/magento",
    name: "Magento",
    component: () => import("@/views/Magento.vue"),
    meta: {
      magentoHeader: true,
      title: "Magento e-commerce solutions",
      metaTags: [
        {
          name: "description",
          content: "Nowadays, more and more businesses migrate online. The pandemic made a considerable impact on offline shops to work on their online presence, therefore it's really reasonable to redirect investment flow from the offline store to the online presence. After all, the investment will be compensated soon because it will be covering an incomparably larger audience, and the online store support requires fewer expenses than offline infrastructure does.",
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Magento e-commerce solutions"
        },
        {
          property: "og:description",
          content: "Nowadays, more and more businesses migrate online. The pandemic made a considerable impact on offline shops to work on their online presence, therefore it's really reasonable to redirect investment flow from the offline store to the online presence. After all, the investment will be compensated soon because it will be covering an incomparably larger audience, and the online store support requires fewer expenses than offline infrastructure does."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-banner.d0075a11.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Magento e-commerce solutions image"
        },
      ]
    },
  },
  {
    path:"/magento-tuning-optimization",
    name:"FindOutMore",
    component:()=> import("@/views/MagentoFindOutMore.vue"),
    meta: {
      magentoHeader: true,
      title: "What we do to accelerate magento sites",
      metaTags: [
        {
          name: "description",
          content: "There are so many reasons why Magento sites might be slow. However, there is always a chance to optimize a Magento site. It’s hard to describe a strict plan for site optimization, because the optimization process really depends on a number of various factors.",
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "What we do to accelerate magento sites"
        },
        {
          property: "og:description",
          content: "There are so many reasons why Magento sites might be slow. However, there is always a chance to optimize a Magento site. It’s hard to describe a strict plan for site optimization, because the optimization process really depends on a number of various factors."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-tuning-optimization"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/bg-magento-find-out.477e9481.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "What we do to accelerate magento sites"
        },
      ]
    },
  },
  {
    path: "/portfolio",
    name: "Portfolio",
    component: () => import("@/views/Portfolio.vue"),
    meta: {
      title: "Portfolio",
      metaTags: [
        {
          name: "description",
          content: "Portfolio of projects of the Wdevs company",
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Portfolio"
        },
        {
          property: "og:description",
          content: "Portfolio of projects of the Wdevs company."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/portfolio"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/design-preview.83be0415.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Portfolio of projects of the Wdevs company"
        },
      ]
    }
  },
  {
    path: "/design-samples",
    name: "DesignSamples",
    component: () => import("@/views/DesignSamples.vue"),
    meta: {
      title: "Design Samples",
      metaTags: [
        {
          name: "description",
          content: "Design samples created by Wdevs company for our clients.",
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Design Samples"
        },
        {
          property: "og:description",
          content: "Design samples created by Wdevs company for our clients."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/design-samples"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/design-preview.83be0415.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Design samples created by Wdevs company for our clients."
        },
      ]
    },
    children:[
      {
        path: "/design-samples/games",
        name: "DesignSamplesGames",
        component: () => import("@/components/DesignSamplesSections/SectionGames.vue"),
        meta: {
          title: "Design samples games",
          metaTags: [
            {
              name: "description",
              content: "Design samples games created by Wdevs company for our clients.",
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:author",
              content: "Wdevs company"
            },
            {
              property: "og:title",
              content: "Design samples games"
            },
            {
              property: "og:description",
              content: "Design samples games created by Wdevs company for our clients.",
            },
            {
              property: "og:url",
              content: "https://wdevs.com/design-samples/games"
            },
            {
              property: "og:image",
              content: "https://wdevs.com/img/design-preview.83be0415.jpg"
            },
            {
              property: "og:site_name",
              content: "https://wdevs.com"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "og:image:width",
              content: "1200"
            },
            {
              property: "og:image:height",
              content: "630"
            },
            {
              property: "og:image:alt",
              content: "Design samples games created by Wdevs company for our clients.",
            },
          ]
        },
      },
      {
        path: "/design-samples/gui",
        name: "DesignSamplesGUI",
        component: () => import("@/components/DesignSamplesSections/SectionGUI.vue"),
        meta: {
          title: "Design samples GUI",
          metaTags: [
            {
              name: "description",
              content: "Design samples GUI created by Wdevs company for our clients.",
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:author",
              content: "Wdevs company"
            },
            {
              property: "og:title",
              content: "Design samples GUI"
            },
            {
              property: "og:description",
              content: "Design samples GUI created by Wdevs company for our clients.",
            },
            {
              property: "og:url",
              content: "https://wdevs.com/design-samples/gui"
            },
            {
              property: "og:image",
              content: "https://wdevs.com/img/design-preview.83be0415.jpg"
            },
            {
              property: "og:site_name",
              content: "https://wdevs.com"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "og:image:width",
              content: "1200"
            },
            {
              property: "og:image:height",
              content: "630"
            },
            {
              property: "og:image:alt",
              content: "Design samples GUI created by Wdevs company for our clients.",
            },
          ]
        },
      },
      {
        path: "/design-samples/mobile",
        name: "DesignSamplesMobile",
        component: () => import("@/components/DesignSamplesSections/SectionMobile.vue"),
        meta: {
          title: "Design samples mobile",
          metaTags: [
            {
              name: "description",
              content: "Design samples mobile created by Wdevs company for our clients.",
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:author",
              content: "Wdevs company"
            },
            {
              property: "og:title",
              content: "Design samples mobile"
            },
            {
              property: "og:description",
              content: "Design samples mobile created by Wdevs company for our clients.",
            },
            {
              property: "og:url",
              content: "https://wdevs.com/design-samples/mobile"
            },
            {
              property: "og:image",
              content: "https://wdevs.com/img/design-preview.83be0415.jpg"
            },
            {
              property: "og:site_name",
              content: "https://wdevs.com"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "og:image:width",
              content: "1200"
            },
            {
              property: "og:image:height",
              content: "630"
            },
            {
              property: "og:image:alt",
              content: "Design samples mobile created by Wdevs company for our clients.",
            },
          ]
        },
      },
      {
        path: "/design-samples/printed",
        name: "DesignSamplesPrinted",
        component: () => import("@/components/DesignSamplesSections/SectionPrinted.vue"),
        meta: {
          title: "Design samples printed",
          metaTags: [
            {
              name: "description",
              content: "Design samples printed created by Wdevs company for our clients.",
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:author",
              content: "Wdevs company"
            },
            {
              property: "og:title",
              content: "Design samples printed"
            },
            {
              property: "og:description",
              content: "Design samples printed created by Wdevs company for our clients.",
            },
            {
              property: "og:url",
              content: "https://wdevs.com/design-samples/printed"
            },
            {
              property: "og:image",
              content: "https://wdevs.com/img/design-preview.83be0415.jpg"
            },
            {
              property: "og:site_name",
              content: "https://wdevs.com"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "og:image:width",
              content: "1200"
            },
            {
              property: "og:image:height",
              content: "630"
            },
            {
              property: "og:image:alt",
              content: "Design samples printed created by Wdevs company for our clients.",
            },
          ]
        },
      },
      {
        path: "/design-samples/web",
        name: "DesignSamplesWeb",
        component: () => import("@/components/DesignSamplesSections/SectionWeb.vue"),
        meta: {
          title: "Design samples web",
          metaTags: [
            {
              name: "description",
              content: "Design samples web created by Wdevs company for our clients.",
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:author",
              content: "Wdevs company"
            },
            {
              property: "og:title",
              content: "Design samples web"
            },
            {
              property: "og:description",
              content: "Design samples web created by Wdevs company for our clients.",
            },
            {
              property: "og:url",
              content: "https://wdevs.com/design-samples/web"
            },
            {
              property: "og:image",
              content: "https://wdevs.com/img/design-preview.83be0415.jpg"
            },
            {
              property: "og:site_name",
              content: "https://wdevs.com"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "og:image:width",
              content: "1200"
            },
            {
              property: "og:image:height",
              content: "630"
            },
            {
              property: "og:image:alt",
              content: "Design samples web created by Wdevs company for our clients.",
            },
          ]
        },
      },
      {
        path: "/design-samples/logos",
        name: "DesignSamplesLogos",
        component: () => import("@/components/DesignSamplesSections/SectionLogos.vue"),
        meta: {
          title: "Design samples logos",
          metaTags: [
            {
              name: "description",
              content: "Design samples logos created by Wdevs company for our clients.",
            },
            {
              property: "og:type",
              content: "website"
            },
            {
              property: "og:author",
              content: "Wdevs company"
            },
            {
              property: "og:title",
              content: "Design samples logos"
            },
            {
              property: "og:description",
              content: "Design samples logos created by Wdevs company for our clients.",
            },
            {
              property: "og:url",
              content: "https://wdevs.com/design-samples/logos"
            },
            {
              property: "og:image",
              content: "https://wdevs.com/img/design-preview.83be0415.jpg"
            },
            {
              property: "og:site_name",
              content: "https://wdevs.com"
            },
            {
              property: "og:locale",
              content: "en_US"
            },
            {
              property: "og:image:width",
              content: "1200"
            },
            {
              property: "og:image:height",
              content: "630"
            },
            {
              property: "og:image:alt",
              content: "Design samples logos created by Wdevs company for our clients.",
            },
          ]
        },
      }

    ]
  },
  {
    path: "/design-samples/:way",
    name: "DesignSamplesBlockWatch",
    component: () => import("@/components/DesignSamplesSections/DesignSamplesBlockWatch.vue"),
  },
  {
    path: "/design-samples/:way/:id",
    name: "DesignSamplesProjectWatch",
    component: () => import("@/components/DesignSamplesSections/DesignSamplesProjectWatch.vue"),
  },
  {
    path: "/cant-find",
    name: "CantFind",
    component: CantFind,
    meta: {
      title: "Can’t find a suitable vendor for your project?",
      metaTags: [
        {
          name: "description",
          content: "Some clients only have vague ideas and they need the help of development services to further develop their concepts. Sometimes the ideas are however so unusual that we don’t have any idea where to start - but we usually always find a way.",
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Can’t find a suitable vendor for your project?"
        },
        {
          property: "og:description",
          content: "Some clients only have vague ideas and they need the help of development services to further develop their concepts. Sometimes the ideas are however so unusual that we don’t have any idea where to start - but we usually always find a way."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/cant-find"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/bg-cant-find.19d8ec8f.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Customer focus"
        },
      ]
    }
  },
  {
    path: "/our-story",
    name: "OurStory",
    component: () => import("@/views/OurStory.vue"),
    meta: {
      title: "Our success story",
      metaTags: [
        {
          name: "description",
          content: "The company was founded in 2007 by Andrey Labunskiy, who started with another 2 guys that were also as dedicated as he was to supporting and developing their first client: https://quotationjunction.com. The MVP exceeded all expectations and the client continued to run their system development through us. While the client’s success grew, so did our dedicated team - while simultaneously sourcing new clients and promoting our services using a referral scheme.",
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Our success story"
        },
        {
          property: "og:description",
          content: "The company was founded in 2007 by Andrey Labunskiy, who started with another 2 guys that were also as dedicated as he was to supporting and developing their first client: https://quotationjunction.com. The MVP exceeded all expectations and the client continued to run their system development through us. While the client’s success grew, so did our dedicated team - while simultaneously sourcing new clients and promoting our services using a referral scheme."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/our-story"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/wdevs-logo-og-image.d29121a8.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Wdevs logo"
        },
      ]
    }
  },
  {
    path: "/portfolio/project/:id",
    name: "PortfolioProjectWatch",
    component: () => import("@/components/PortfolioProjectWatch.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("@/views/Contacts.vue"),
    meta: {
      title: "Contact us",
      metaTags: [
        {
          name: "description",
          content: "We would love to hear from you",
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Contact us"
        },
        {
          property: "og:description",
          content: "We would love to hear from you"
        },
        {
          property: "og:url",
          content: "https://wdevs.com/contacts"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/wdevs-logo-og-image.d29121a8.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Wdevs logo"
        },
      ]
    }
  },
  {
    path: "/available-developers",
    name: "Available Developers",
    component: () => import("@/views/AvailableDevelopers.vue"),
    meta: {
      title: "Available Developers",
      metaTags: [
        {
          name: "description",
          content: "We would love to hear from you",
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Available Developers"
        },
        {
          property: "og:description",
          content: "We would love to hear from you"
        },
        {
          property: "og:url",
          content: "https://wdevs.com/contacts"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/wdevs-logo-og-image.d29121a8.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Wdevs logo"
        },
      ]
    }
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("@/views/Blog.vue"),
    meta: {
      title: "Blog",
      metaTags: [
        {
          name: "description",
          content: "Blog of the Wdevs company",
        },
        {
          property: "og:type",
          content: "website"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Blog"
        },
        {
          property: "og:description",
          content: "Blog of the Wdevs company"
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/wdevs-logo-og-image.d29121a8.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Wdevs logo"
        },
      ]
    },
  },
  {
    path:"/blog/razzo",
    name: "ProjectRazzo",
    component: () => import("@/components/Razzo/ProjectRazzo.vue"),
    meta: {
      title: "Scale up your eCommerce with Razzo",
      metaTags: [
        {
          name: "description",
          content: "Razzo.io helps Magento websites owners analyze their clients’ needs & customer behaviour by delivering the most accurate data in simple and informative graphics.",
        },
        {
          name: "keywords",
          content: "Magento, Razzo, eCommerce, Custom widget for Magento, Web Development, Magento extension"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Scale up your eCommerce with Razzo"
        },
        {
          property: "og:description",
          content: "Razzo.io helps Magento websites owners analyze their clients’ needs & customer behaviour by delivering the most accurate data in simple and informative graphics."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/razzo"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/razzo.70e09573.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Scale up your eCommerce with Razzo image"
        },
      ]
    }
  },
  {
    path:"/blog/cant-find",
    name:'BlogCantFind',
    component: CantFind,
  },
  {
    path:"/blog/erp",
    name:'BlogEpr',
    component: () => import("@/components/Blog/BlogEpr.vue"),
    meta: {
      title: "Introduction to ERP-eCommerce integration",
      metaTags: [
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Introduction to ERP-eCommerce integration"
        },
        {
          property: "og:description",
          content: "This article gives a complete overview of eCommerce-ERP integration and how it can assist in automating your business processes."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/erp/"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/epr-article-top-img.b7ba7966.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Introduction to ERP-eCommerce integration image"
        },
        {
          name: "description",
          content: "This article gives a complete overview of eCommerce-ERP integration and how it can assist in automating your business processes"
        },
        {
          name: "keywords",
          content: "ERP integration, eCommerce, Magento, CMS, Custom Web Development, Third-party solutions"
        },
      ]
    }
  },
  {
    path:"/blog/home-delivery-date",
    name:'BlogHomeDeliveryDate',
    component: () => import("@/components/Blog/BlogHomeDeliveryDate.vue"),
    meta: {
      title: "Custom Home Delivery Solution for eCommerce",
      metaTags: [
        {
          name: "description",
          content: "Custom solution by WDevs for fast delivery management, which allows the brand to adapt its eCommerce sales channel to the existing business processes."
        },
        {
          name: "keywords",
          content: "Delivery Calculator, eCommerce, Custom plugin for Magento, Custom Website Development, Magento Solution"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Custom Home Delivery Solution for eCommerce"
        },
        {
          property: "og:description",
          content: "Custom solution by WDevs for fast delivery management, which allows the brand to adapt its eCommerce sales channel to the existing business processes."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/home-delivery-date"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/home-delivery-date-top-img.90822012.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Custom Home Delivery Solution for eCommerce image"
        },
      ]
    }
  },
  {
    path:"/blog/security-audit",
    name:'BlogSecurityAudit',
    component: () => import("@/components/Blog/BlogSecurityAudit.vue"),
    meta: {
      title: "Case Study: the client’s Magento site was compromised",
      metaTags: [
        {
          name: "description",
          content: "The client’s case by WDEVS shared here serves as a good example of the importance of Magento security audit services & its effectiveness for your business."
        },
        {
          name: "keywords",
          content: "Magento Audit, eCommerce, Custom Web Development, Security Audit, Security Patches, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:author",
          content: "Wdevs company"
        },
        {
          property: "og:title",
          content: "Case Study: the client’s Magento site was compromised"
        },
        {
          property: "og:description",
          content: "The client’s case by WDEVS shared here serves as a good example of the importance of Magento security audit services & its effectiveness for your business."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/security-audit"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/security-audit.f380bbaa.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Case Study: the client’s Magento site was compromised image"
        },
      ]
    }
  },
  {
    path:"/blog/keep-afloat-by-outsourcing-web-development-during-recession",
    name:'BlogOutsourcingWebDevelopment',
    component: () => import("@/components/Blog/BlogOutsourcingWebDevelopment.vue"),
    meta: {
      title: "Outsourcing Web Development During a Recession",
      metaTags: [
        {
          name: "description",
          content: "Web Dev outsourcing offers flexible working solutions for your business during recession."
        },
        {
          name: "keywords",
          content: "Magento Outsource, eCommerce, Custom Web Development, IT Outsourcing, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Outsourcing Web Development During a Recession"
        },
        {
          property: "og:description",
          content: "How to keep afloat by outsourcing web development during a recession."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/keep-afloat-by-outsourcing-web-development-during-recession"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/development-during-recession-top-og-img.f424f8b4.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Outsourcing web development during a recession"
        },
      ]
    }
  },
  {
    path: "/blog/code-review",
    name: "BlogCodeReview",
    component: () => import("@/components/Blog/BlogCodeReview.vue"),
    meta: {
      title: "Code Review And How It Really Benefits Your Business",
      metaTags: [
        {
          name: "description",
          content: "WDEVS offers code review as a professional service to ensure that every stage of your project development is effective and brings value. Contact our team to get more info."
        },
        {
          name: "keywords",
          content: "Code Review, eCommerce, Custom Web Development, Security, Audit, Outsourcing Web Development"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Code Review And How It Really Benefits Your Business"
        },
        {
          property: "og:description",
          content: "WDEVS offers code review as a professional service to ensure that every stage of your project development is effective and brings value. Contact our team to get more info."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/outsourcing-company-vs-recruiting-agency"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/outsourcing-company-vs-recruiting-agency.a43f02ce.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Hiring with Outsourcing Company vs Recruiting Agency"
        },
      ]
    }
  },
  {
    path: "/blog/magento-code-review",
    name: "BlogMagentoCodeReview",
    component: () => import("@/components/Blog/BlogMagentoCodeReview.vue"),
    meta: {
      title: "The Ultimate Guide to Magento Code Review",
      metaTags: [
        {
          name: "description",
          content: "WDEVS provides a detailed code audit to ensure the functional performance of your Magento store is top-notch."
        },
        {
          name: "keywords",
          content: "Magento, eCommerce, Custom Web Development, Code Audit, Code Review, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "THE ULTIMATE GUIDE TO MAGENTO CODE REVIEW: CRUCIAL STEPS TO E-COMMERCE SUCCESS"
        },
        {
          property: "og:description",
          content: "WDEVS provides a detailed code audit to ensure the functional performance of your Magento store is top-notch."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/magento-code-review"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-code-review.8ec9bd32.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "The Ultimate Guide to Magento Code Review"
        },
      ]
    }
  },
  {
    path: "/blog/outsourcing-company-vs-recruiting-agency",
    name: "BlogOutsourcingRecruiting",
    component: () => import("@/components/Blog/BlogOutsourcingRecruiting.vue"),
    meta: {
      title: "Hiring with Outsourcing Company vs Recruiting Agency",
      metaTags: [
        {
          name: "description",
          content: "WDEVS offers an experienced dev team for your project with no extra fees or hidden payments. You pay for what you really need!"
        },
        {
          name: "keywords",
          content: "Outsourcing, eCommerce, Custom Web Development, Hiring, Talent Acquisition, Recruitment, White Label"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "The Pros and Cons of Hiring with Outsourcing Web Development Company vs. Recruitment Agency"
        },
        {
          property: "og:description",
          content: "WDEVS offers an experienced dev team for your project with no extra fees or hidden payments. You pay for what you really need!"
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/outsourcing-company-vs-recruiting-agency"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/outsourcing-company-vs-recruiting-agency.a43f02ce.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "The Pros and Cons of Hiring with Outsourcing Web Development Company vs. Recruitment Agency"
        },
      ]
    }
  },
  {
    path: "/blog/buissonniere-e-commerce-success-story",
    name: "BlogBuissonniere",
    component: () => import("@/components/Blog/BlogBuissonniere.vue"),
    meta: {
      title: "Navigating fashion & apparel online: Buissonniere's e-commerce success story",
      metaTags: [
        {
          name: "description",
          content: "WDEVS offers an experienced dev team for your project with no extra fees or hidden payments. You pay for what you really need!"
        },
        {
          name: "keywords",
          content: "Outsourcing, eCommerce, Custom Web Development, Hiring, Talent Acquisition, Recruitment, White Label"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Navigating fashion & apparel online: Buissonniere's e-commerce success story"
        },
        {
          property: "og:description",
          content: "The Fashion & Apparel market is extremely competitive and saturated. With so many businesses vying for consumer attention, it can be difficult for a new brand to stand out and make a name for itself. Our client is a growing Belgian e-commerce company with several offline shops and worldwide shipping. Their existing online platform faced several challenges at some point, including underdeveloped features and numerous bugs, that’s why they decided to reach our experienced team."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/buissonniere-e-commerce-success-story"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/buissonniere-e-commerce-success-story.fdb932d6.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Navigating fashion & apparel online: Buissonniere's e-commerce success story"
        },
      ]
    }
  },
  {
    path: "/blog/adobe-commerce-vs-magento",
    name: "BlogAdobeCommerceVsMagento",
    component: () => import("@/components/Blog/BlogAdobeCommerceVsMagento.vue"),
    meta: {
      title: "Adobe commerce vs. magento: choosing the ultimate solution for e-commerce",
      metaTags: [
        {
          name: "description",
          content: "WDEVS provides Adobe Commerce Cloud and Magento development services for all stages of the e-commerce project life cycle."
        },
        {
          name: "keywords",
          content: "Magento, Adobe Commerce, eCommerce, Custom Web Development, Adobe Commerce Cloud, Magento 2, Web Development Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Adobe commerce vs. magento: choosing the ultimate solution for e-commerce"
        },
        {
          property: "og:description",
          content: "WDEVS provides Adobe Commerce Cloud and Magento development services for all stages of the e-commerce project life cycle."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/adobe-commerce-vs-magento"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/adobe-commerce-vs-magento.0a643d1f.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Adobe commerce vs. magento: choosing the ultimate solution for e-commerce"
        },
      ]
    }
  },
  {
    path: "/blog/shopify-limitations-for-e-commerce",
    name: "BlogShopifyLimitations",
    component: () => import("@/components/Blog/BlogShopifyLimitations.vue"),
    meta: {
      title: "Shopify's limitations for e-commerce business",
      metaTags: [
        {
          name: "description",
          content: "Thinking about migrating from Shopify? WDEVS ensures secure website migration from Shopify to any other platform you choose."
        },
        {
          name: "keywords",
          content: "Shopify, Adobe Commerce, eCommerce, Website Migration, Custom Web Development, Shopify Restrictions, Online Store, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Shopify's limitations for e-commerce business"
        },
        {
          property: "og:description",
          content: "Thinking about migrating from Shopify? WDEVS ensures secure website migration from Shopify to any other platform you choose."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/shopify-limitations-for-e-commerce"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/shopify-limitations-for-e-commerce.10181829.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Shopify's limitations for e-commerce business"
        },
      ]
    }
  },
  {
    path: "/blog/vape-shop-custom-website",
    name: "BlogVapeShopCustomWebsite",
    component: () => import("@/components/Blog/BlogVapeShopCustomWebsite.vue"),
    meta: {
      title: "Why your vape shop needs a custom website?",
      metaTags: [
        {
          name: "description",
          content: "WDEVS offers cost-effective custom web development solutions for vape stores worldwide."
        },
        {
          name: "keywords",
          content: "Magento, eCommerce, Vape Store, Custom Web Development, Vape Shop, Web Design, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Why your vape shop needs a custom website?"
        },
        {
          property: "og:description",
          content: "WDEVS offers cost-effective custom web development solutions for vape stores worldwide."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/vape-shop-custom-website"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/vape-shop-custom-website.bd7c66a2.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Why your vape shop needs a custom website?"
        },
      ]
    }
  },
  {
    path: "/blog/driving-sales-home-for-the-holidays",
    name: "BlogDrivingSalesHoliday",
    component: () => import("@/components/Blog/BlogDrivingSalesHoliday.vue"),
    meta: {
      title: "Simple web development solutions for e-commerce during the holidays",
      metaTags: [
        {
          name: "description",
          content: "WDEVS offers expert insights and strategies to boost your online sales during high season. Discover effortless web development solutions to elevate your online business!"
        },
        {
          name: "keywords",
          content: "Holiday Sales, eCommerce, Web Solutions, Custom Web Development, Christmas Discounts, Web Design, Magento, Shopify, WooCommerce"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Driving sales home for the holidays: simple web development solutions for boosting e-commerce during the holidays"
        },
        {
          property: "og:description",
          content: "WDEVS offers expert insights and strategies to boost your online sales during high season. Discover effortless web development solutions to elevate your online business!"
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/driving-sales-home-for-the-holidays"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/driving-sales-home-for-the-holidays.601a9b53.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Simple web development solutions for e-commerce during the holidays"
        },
      ]
    }
  },
  {
    path: "/blog/handling-massive-product-db-in-magento",
    name: "BlogMassiveProductDB",
    component: () => import("@/components/Blog/BlogMassiveProductDB.vue"),
    meta: {
      title: "Handling Massive Product Databases In Magento",
      metaTags: [
        {
          name: "description",
          content: "From seamless user experience to scalable solutions, WDEVS are experts in developing Magento storefronts that redefine online retail"
        },
        {
          name: "keywords",
          content: "Magento, eCommerce, Data Import, Product Database, Magento Database, Custom Web Development, Online Retail, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Handling Massive Product Databases In Magento"
        },
        {
          property: "og:description",
          content: "From seamless user experience to scalable solutions, WDEVS are experts in developing Magento storefronts that redefine online retail"
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/handling-massive-product-db-in-magento"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/handling-massive-product-db-in-magento.31963287.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Handling Massive Product Databases In Magento"
        },
      ]
    }
  },
  {
    path: "/blog/magento-vs-woocommerce-showdown",
    name: "BlogMagentoVSWooCommerce",
    component: () => import("@/components/Blog/BlogMagentoVSWooCommerce.vue"),
    meta: {
      title: "Magento vs WooCommerce for your business",
      metaTags: [
        {
          name: "description",
          content: "WooCommerce vs Magento: Your WDEVS guide to select the best e-commerce solution. Our article explores key features, scalability, and insights to aid your choice."
        },
        {
          name: "keywords",
          content: "WooCommerce, eCommerce, Magento, Custom Web Development, Cyber Security, Online Shopping, Enterprise Web Solutions, WordPress"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Magento vs WooCommerce for your business"
        },
        {
          property: "og:description",
          content: "WooCommerce vs Magento: Your WDEVS guide to select the best e-commerce solution. Our article explores key features, scalability, and insights to aid your choice."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/magento-vs-woocommerce-showdown"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-vs-woocommerce-showdown.8ad1eda3.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Magento vs WooCommerce for your business"
        },
      ]
    }
  },
  {
    path: "/blog/chat-gpt-integration",
    name: "BlogChatGptIntegration",
    component: () => import("@/components/Blog/BlogChatGptIntegration.vue"),
    meta: {
      title: "Enhancing E-Commerce With ChatGPT",
      metaTags: [
        {
          name: "description",
          content: "Transform your e-commerce platform with custom AI-driven solutions by WDEVS to boost customer satisfaction, and elevate your online store's performance!"
        },
        {
          name: "keywords",
          content: "WooCommerce, eCommerce, Magento, Custom Web Development, Cyber Security, Online Shopping, Enterprise Web Solutions, WordPress"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Enhancing E-Commerce With ChatGPT"
        },
        {
          property: "og:description",
          content: "Transform your e-commerce platform with custom AI-driven solutions by WDEVS to boost customer satisfaction, and elevate your online store's performance!"
        },
        {
          property: "og:url",
          content: "https://wdevs.com/blog/chat-gpt-integration"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/chat-gpt-integration-cover.c0f7134f.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Handling Massive Product Databases In Magento"
        },
      ]
    }
  },
  {
    path: "/magento-setup-tuning",
    name: "MagentoSetupTuning",
    component: () => import("@/views/MagentoSetupTuning"),
    meta: {
      magentoHeader: true,
      title: "Magento Websites Setup and Tuning",
      metaTags: [
        {
          name: "description",
          content: "Quick and secure setup and tuning for your Magento store by WDevs. Top-notch performance and service guarantee."
        },
        {
          name: "keywords",
          content: "Magento Setup, eCommerce, Magento Installation, Custom Web Development, Magento Tuning"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Magento websites setup and tuning"
        },
        {
          property: "og:description",
          content: "Quick and secure setup and tuning for your Magento store by WDevs. Top-notch performance and service guarantee."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-setup-tuning"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-setup-tuning.5a6c4568.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Magento websites setup and tuning"
        },
      ]
    }
  },
  {
    path: "/magento-support-security",
    name: "MagentoSupportSecurity",
    component: () => import("@/views/MagentoSupportSecurity"),
    meta: {
      magentoHeader: true,
      title: "Magento Support and Security Patching",
      metaTags: [
        {
          name: "description",
          content: "WDevs will help you install all necessary Magento security patches and improve your website's safety. Timely updates and full support with our Magento services."
        },
        {
          name: "keywords",
          content: "Magento Security, eCommerce, Security Patch Installation, Custom Web Development, Magento Support"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Magento support and security patching"
        },
        {
          property: "og:description",
          content: "WDevs will help you install all necessary Magento security patches and improve your website's safety. Timely updates and full support with our Magento services."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-support-security"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-support-security.456f4e6d.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Magento support and security patching"
        },
      ]
    }
  },
  {
    path: "/magento-custom-development",
    name: "MagentoCustomDevelopment",
    component: () => import("@/views/MagentoCustomDevelopment"),
    meta: {
      magentoHeader: true,
      title: "Custom Development for Magento",
      metaTags: [
        {
          name: "description",
          content: "WDevs offers a wide range of Custom Magento development services: website customization, extensions development and third-party solutions integration."
        },
        {
          name: "keywords",
          content: "Magento Customization, eCommerce, ERP integration, Custom Web Development, Custom Magento extensions"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Custom development for Magento"
        },
        {
          property: "og:description",
          content: "WDevs offers a wide range of Custom Magento development services: website customization, extensions development and third-party solutions integration."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-custom-development"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-custom-development.76c11ffb.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Custom development for Magento"
        },
      ]
    }
  },
  {
    path: "/magento-modules-customization",
    name: "MagentoModulesCustomization",
    component: () => import("@/views/MagentoModulesCustomization"),
    meta: {
      magentoHeader: true,
      title: "Third Party Modules Customization",
      metaTags: [
        {
          name: "description",
          content: "WDevs provides third party modules complete customization syncing them with your Magento store."
        },
        {
          name: "keywords",
          content: "Magento Module Customization, eCommerce, Third-Party Solutions integration, Custom Web Development, Custom Magento extensions"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Third party modules customization"
        },
        {
          property: "og:description",
          content: "WDevs provides third party modules complete customization syncing them with your Magento store."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-modules-customization"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-modules-customization.d111a541.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Third party modules customization"
        },
      ]
    }
  },
  {
    path: "/magento-services-integration",
    name: "MagentoServicesIntegration",
    component: () => import("@/views/MagentoServicesIntegration"),
    meta: {
      magentoHeader: true,
      title: "Third Party Services Integration",
      metaTags: [
        {
          name: "description",
          content: "WDevs provides third party services integration syncing your Magento store with ERP systems, marketplaces, payment methods, and other third-party solutions."
        },
        {
          name: "keywords",
          content: "Magento Third Party Integration, eCommerce, ERP integration, Custom Web Development, Custom Magento solution, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Third party services integration"
        },
        {
          property: "og:description",
          content: "WDevs provides third party services integration syncing your Magento store with ERP systems, marketplaces, payment methods, and other third-party solutions."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-services-integration"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-services-integration.ae2c19d9.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Third party services integration"
        },
      ]
    }
  },
  {
    path: "/magento-migration",
    name: "MagentoMigration",
    component: () => import("@/views/MagentoMigration"),
    meta: {
      magentoHeader: true,
      title: "Migration to Magento from Different eCommerce Platforms",
      metaTags: [
        {
          name: "description",
          content: "WDevs works with you to analyze your current website structure, data logic to flawlessly migrate your site to Magento, while ensuring your data integrity."
        },
        {
          name: "keywords",
          content: "Magento Migration, eCommerce, Custom Web Development, Migrate your site to Magento, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Migration to Magento from different eCommerce platforms"
        },
        {
          property: "og:description",
          content: "WDevs works with you to analyze your current website structure, data logic to flawlessly migrate your site to Magento, while ensuring your data integrity."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-migration"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-migration.65a66fac.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Migration to Magento from different eCommerce platforms"
        },
      ]
    }
  },
  {
    path: "/magento-version-switching",
    name: "MagentoVersionSwitching",
    component: () => import("@/views/MagentoVersionSwitching"),
    meta: {
      magentoHeader: true,
      title: "Switching from Magento 1x to Magento 2x",
      metaTags: [
        {
          name: "description",
          content: "Magento 2 offers its clients a modern, user-friendly, responsive design, optimized performance & enhanced security; as well as dynamic, cloud-based architecture, better customer experience and so much more."
        },
        {
          name: "keywords",
          content: ""
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Switching from Magento 1x to Magento 2x"
        },
        {
          property: "og:description",
          content: "Magento 2 offers its clients a modern, user-friendly, responsive design, optimized performance & enhanced security; as well as dynamic, cloud-based architecture, better customer experience and so much more."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-version-switching"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-version-switching.c8ca358d.jpg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Switching from Magento 1x to Magento 2x"
        },
      ]
    }
  },
  {
    path: "/magento-security-services",
    name: "MagentoSecurityServices",
    component: () => import("@/views/MagentoSecurityServices"),
    meta: {
      magentoHeader: true,
      title: "Security Audit",
      metaTags: [
        {
          name: "description",
          content: "Magento Security Audit Services. Over 15 years of proven expertise in eCommerce. Contact our team to get more info about the services."
        },
        {
          name: "keywords",
          content: "Magento Audit, eCommerce, Custom Web Development, Security Audit, Security Patches, Magento Services"
        },
        {
          property: "og:type",
          content: "article"
        },
        {
          property: "og:title",
          content: "Security audit"
        },
        {
          property: "og:description",
          content: "Magento Security Audit Services. Over 15 years of proven expertise in eCommerce. Contact our team to get more info about the services."
        },
        {
          property: "og:url",
          content: "https://wdevs.com/magento-security-services"
        },
        {
          property: "og:image",
          content: "https://wdevs.com/img/magento-security-services.48cd8b86.jpeg"
        },
        {
          property: "og:site_name",
          content: "https://wdevs.com"
        },
        {
          property: "og:locale",
          content: "en_US"
        },
        {
          property: "og:image:width",
          content: "1200"
        },
        {
          property: "og:image:height",
          content: "630"
        },
        {
          property: "og:image:alt",
          content: "Security audit"
        },
      ]
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      if(window.location.pathname ==='/portfolio/project/resolute-intranet-and-sundashboard/'){
        return {
          el: 'to.hash',
          top: -110,
        }
      }
      if(window.location.hash === "#mg_prices"){
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              el: to.hash,
              top:60,
              behavior: 'smooth',
            })
          }, 100)
        })
      }
      else{
        return window.scrollTo({
          top: document.querySelector(to.hash).offsetTop-60,
          behavior: "smooth",
        });
      }
    }
    if(window.location.pathname === '/'){
      return {
        top: 0,
        behavior: 'smooth',
      }
    }
    return {
      top: 0
    }
  },
});

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const elemBefore = document.querySelector("link[rel=icon]")
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta) return next();
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });
    return tag;
  })
  .forEach(tag => document.head.insertBefore(tag, elemBefore));
  next();
});
export default router;
