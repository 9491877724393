<template>
<header id="header">
    <div class="container">
        <div class="header_content">
            <div class="header_left">
                <button class="burger-menu" @click="show = !show" aria-label="burger-menu">
                    <img v-svg-inline :data-src="require('@/assets/icons/burger-icon.svg')" alt="Collapse">
                </button>
                <router-link to="/" class="logo" aria-label="logo">
                    <img v-svg-inline :data-src="require('@/assets/icons/logo-magento.svg')" alt="Wdevs" />
                </router-link>
                <router-link to="/" class="secondary button go-web-mobile">Go to Web & Mobile</router-link>
            </div>
            <div class="header_right header_right--magento">
              <HeaderMenuItems :magento="true" />
                <button class="magento-btn button" @click="showModal = true">Start a Project</button>
                <ModalContact v-if="showModal"
                  @close="showModal = false"
                  :magento="true"
                  :startProjectModal="true">
                  <h2>Start a Project</h2>
                </ModalContact>
            </div>
        </div>
         <transition name="burger">
        <ModalHumburgerMenu v-if="show" @close="show = false" :magento="true"/>
      </transition>
    </div>
</header>
</template>
<script>
import ModalContact from "../ModalContact.vue"
import ModalHumburgerMenu from "../ModalHumburgerMenu.vue"
import HeaderMenuItems from "../HeaderMenuItems.vue";

export default{
    components:{
      ModalContact,
      ModalHumburgerMenu,
      HeaderMenuItems,
    },
    data(){
      return{
          show:false,
          showModal:false,
      }
  }
}
</script>


<style lang="scss">
@import "../../scss/variables";
@import "include-media/dist/_include-media.scss";

.header_left {
    .go-web-mobile {
        display: none;
        @include media('>=tablet') {
            display: inline-flex;
            text-decoration: none;
            color: $black;
            border-color: $orange;
            &:hover {
                color: $white;
            }
        }
    }
}
.header_right--magento {
    #nav {
        .nav_link {
            margin-right: 20px;
            @include media('>=desktop') {
                margin-right: 40px;
            }
            &:hover {
                color: $orange;
                opacity: 1;
            }
        }
      .dropdown {
        padding-top: 0;
      }
    }
}
</style>
