<template>
  <ul class="socials-list" :class="{'socials-list--white': whiteIcons}">
    <li class="socials-list__item" v-for="item in socialsList" :key="item.id">
      <a :href="item.link" target="_blank" class="socials-list__item-link" :aria-label="item.name">
        <template v-if="whiteIcons">
          <img
              v-svg-inline
              :data-src="require('@/assets/icons/white/' + item.name + '.svg')"
              :alt="item.name"
              class="socials-list__item-image"
          />
        </template>
        <template v-else>
          <img
              v-svg-inline
              :data-src="require('@/assets/icons/' + item.name + '.svg')"
              :alt="item.name"
              class="socials-list__item-image"
          />
        </template>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  data(){
    return{
    }
  },
  props:{
    socialsList: {
      type: Array,
      default: () => {return []},
    },
    whiteIcons: {
      type: Boolean,
      default: false,
      description: 'Make social network icons white',
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./src/scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.socials-list {
  @extend %listreset;
  display: flex;
  &__item {
    margin-right: 20px;
    &-link {
      display: block;
      width: 40px;
      height: 40px;
    }
    &-image {
      display: block;
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
  }
  &--white {
    margin-bottom: 10px;
  }
}
</style>