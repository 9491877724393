<template>
<ReferencesSliderSection dark='true' magento='' :feedbacks="home_feedbacks" title="References"/>
</template>

<script>
import ReferencesSliderSection from "./ReferencesSliderSection.vue";
import { mapState } from "vuex";

export default {
  components:{
    ReferencesSliderSection
  },
  computed: {
    ...mapState(["home_feedbacks"]),
  },
};
</script>
