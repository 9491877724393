<template>
  <div class="cant-find-page" @scroll="scrolling()">
    <section class="cf__banner">
      <picture class="cf__banner-img">
        <source
          media="(max-width: 768px)"
          srcset="
            @/assets/cant-find/bg-cant-find_small.webp    1x,
            @/assets/cant-find/bg-cant-find_small@2x.webp 2x
          "
        />
        <source
          media="(max-width: 768px)"
          srcset="
            @/assets/cant-find/bg-cant-find_small.jpg    1x,
            @/assets/cant-find/bg-cant-find_small@2x.jpg 2x
          "
        />

        <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/cant-find/bg-cant-find_medium.webp    1x,
            @/assets/cant-find/bg-cant-find_medium@2x.webp 2x
          "
        />
        <source
          media="(min-width: 768px) and (max-width: 1199px)"
          srcset="
            @/assets/cant-find/bg-cant-find_medium.jpg    1x,
            @/assets/cant-find/bg-cant-find_medium@2x.jpg 2x
          "
        />

        <source
          srcset="
            @/assets/cant-find/bg-cant-find.webp    1x,
            @/assets/cant-find/bg-cant-find@2x.webp 2x
          "
        />
        <source
          srcset="
            @/assets/cant-find/bg-cant-find.jpg    1x,
            @/assets/cant-find/bg-cant-find@2x.jpg 2x
          "
        />

        <img
          src="@/assets/cant-find/bg-cant-find.jpg"
          alt="Can’t find a suitable vendor for your project"
          loading="lazy"
          width="1920"
          height="640"
        />
      </picture>
      <h1 class="cf__banner-title">
        Can’t Find A Suitable Vendor For Your Project?
      </h1>
    </section>
      <section-layout name="cf__first" title="" margin="true">
        <ul>
          <li>
            You want to develop something dynamic or unique and it’s extremely
            difficult to find developers who are capable of helping you to
            realize your project?
          </li>
          <li>
            You have a conceptual idea, but need someone who is capable of
            developing the idea into something comprehensible which could be
            developed into a tangible project?
          </li>
          <li>
            You are looking to develop something based on recent technology and
            there are not enough qualified devs in your region to manage it?
          </li>
          <li>
            Some clients have existing online presence since the early 2000s
            <ul>
              <li>
                and their technology is outdated so it’s difficult to find
                developers who are willing to work on their coding,
              </li>
              <li>
                not to mention that it’s impossible to update their server
                environments, because the existing code can’t run on new CGI and
                scripting languages.
              </li>
            </ul>
          </li>
          <li>
            You’ve tired of looking for a decent agency, because they all
            promised good results initially but all eventually failed.
          </li>
        </ul>
        <p>
          The list could go on and on, but the key factor here is that if you
          have not yet successfully found the best developers for turning your
          ideas and dreams into a tangibly marketable reality; as well as
          continually supporting your product, then please consider the
          following points regarding our services:
        </p>
      </section-layout>

      <section-layout name="cf__innovations" title="Innovations" margin="true">
        <p>
          It would be dishonest to say that we are capable of covering
          everything, but there are a number of points which go to show that we
          are capable of handling situations which usually cause other
          developers to hang their heads in dismay and retire from the project.
        </p>
        <picture class="cf__img">
          <source
            media="(max-width: 480px)"
            srcset="
              @/assets/cant-find/innovations_small.webp    1x,
              @/assets/cant-find/innovations_small@2x.webp 2x
            "
          />
          <source
            media="(max-width: 480px)"
            srcset="
              @/assets/cant-find/innovations_small.jpg    1x,
              @/assets/cant-find/innovations_small@2x.jpg 2x
            "
          />

          <source
            media="(min-width: 480px) and (max-width: 1199px)"
            srcset="
              @/assets/cant-find/innovations_medium.webp    1x,
              @/assets/cant-find/innovations_medium@2x.webp 2x
            "
          />
          <source
            media="(min-width: 480px) and (max-width: 1199px)"
            srcset="
              @/assets/cant-find/innovations_medium.jpg    1x,
              @/assets/cant-find/innovations_medium@2x.jpg 2x
            "
          />

          <source
            srcset="
              @/assets/cant-find/innovations.webp    1x,
              @/assets/cant-find/innovations@2x.webp 2x
            "
          />
          <source
            srcset="
              @/assets/cant-find/innovations.jpg    1x,
              @/assets/cant-find/innovations@2x.jpg 2x
            "
          />

          <img
            src="@/assets/cant-find/innovations.jpg"
            alt="Innovations section image"
            loading="lazy"
            width="1147"
            height="709"
          />
        </picture>
        <ul>
          <li>
            It’s impossible to stay static - the only options are to either move
            forward or remain treading water in a stationary position and
            finally deteriorate before you disappear. We are always striving to
            move forward and eager to help new companies find their direction:
            <p>
              So; for example, in the case of a client looking for unique
              resources, or wanting to be a part of some new IT trend from its
              inception - we are always happy to comply and walk the entire
              distance with them, helping, as well as adapting each step of the
              way.
            </p>
            <p>
              Please see a few examples below of projects we have been involved
              with in the past:
            </p>
            <ul>
              <li>
                We started developing for Android only a few months after their
                first Android phone had been released in 2008.
              </li>
              <li>
                We set up an exclusive Magento team for a previous partnership
                to support many of their new Magento extensions, and since then
                we have exponentially continued growing in this direction.
              </li>
              <li>
                We set up a fully trained and functional team of 6 data
                capturing personnel within a month after a client had requested
                it.
              </li>
              <li>
                We started developing for Apple TV as soon as Apple announced
                TVML
              </li>
            </ul>
          </li>
        </ul>
      </section-layout>

      <section-layout name="cf__process" title="Process" margin="true">
        <p>
          We had clients who wanted to experiment with a new concept and we
          helped them to conceptualize it; from documenting their ideas into
          technical specifications; to developing the MVP, and just generally
          bringing their product to life:
        </p>
        <picture class="cf__img">
          <source
            media="(max-width: 480px)"
            srcset="
              @/assets/cant-find/process_small.webp    1x,
              @/assets/cant-find/process_small@2x.webp 2x
            "
          />
          <source
            media="(max-width: 480px)"
            srcset="
              @/assets/cant-find/process_small.jpg    1x,
              @/assets/cant-find/process_small@2x.jpg 2x
            "
          />

          <source
            media="(min-width: 480px) and (max-width: 1199px)"
            srcset="
              @/assets/cant-find/process_medium.webp    1x,
              @/assets/cant-find/process_medium@2x.webp 2x
            "
          />
          <source
            media="(min-width: 480px) and (max-width: 1199px)"
            srcset="
              @/assets/cant-find/process_medium.jpg    1x,
              @/assets/cant-find/process_medium@2x.jpg 2x
            "
          />

          <source
            srcset="
              @/assets/cant-find/process.webp    1x,
              @/assets/cant-find/process@2x.webp 2x
            "
          />
          <source
            srcset="
              @/assets/cant-find/process.jpg    1x,
              @/assets/cant-find/process@2x.jpg 2x
            "
          />

          <img
            src="@/assets/cant-find/process.jpg"
            alt="Process section image"
            loading="lazy"
            width="1147"
            height="709"
          />
        </picture>
        <ul>
          <li>
            <a href="/portfolio/project/quotation-junction"
              >Quotationjunction.com</a
            >. The client proposed an idea which would allow people to trade
            their deals freely. They had a limited budget to implement the idea,
            so we discussed what could be done within the agreed upon 200 hours.
            We developed the MVP and we knew that the idea was working when the
            trial users loved the idea. We continued its development and brought
            ideas to the table on how to improve the MVP and turn it into a
            long-term project with a team of 7 devs involved.
          </li>
          <li>
            <a href="/portfolio/project/traffic-generation-system"
              >Traffic Generation system</a
            >. The client asked us to devise a way to replace an HREF link in
            HTTP requests so that the sites which received a redirected user,
            considered the user to have originated from the site specified in
            the HREF source link. We offered a few ways to execute their ideas
            and after we helped them prove that the idea could work, the client
            began building a collaborative system with us which would be able to
            direct traffic to any source.
          </li>
          <li>
            A trusted partner wanted to jump into the gaming development
            industry and asked us to propose a quick way to do it. Within a
            short period of time we had discovered a number of frameworks for
            game development and settled on Lua/Corona. As a result; within a
            month we had our first completed game developed and within a year we
            had developed a number of awesome
            <a href="/portfolio/project/games">games</a>.
          </li>
        </ul>
      </section-layout>

      <section-layout name="cf__customer" title="Customer focus" margin="true">
        <p>
          Some clients only have vague ideas and they need the help of
          development services to further develop their concepts. Sometimes the
          ideas are however so unusual that we don’t have any idea where to
          start - but we usually always find a way.
        </p>
        <picture class="cf__img">
          <source
            media="(max-width: 480px)"
            srcset="
              @/assets/cant-find/customer_small.webp    1x,
              @/assets/cant-find/customer_small@2x.webp 2x
            "
          />
          <source
            media="(max-width: 480px)"
            srcset="
              @/assets/cant-find/customer_small.jpg    1x,
              @/assets/cant-find/customer_small@2x.jpg 2x
            "
          />

          <source
            media="(min-width: 480px) and (max-width: 1199px)"
            srcset="
              @/assets/cant-find/customer_medium.webp    1x,
              @/assets/cant-find/customer_medium@2x.webp 2x
            "
          />
          <source
            media="(min-width: 480px) and (max-width: 1199px)"
            srcset="
              @/assets/cant-find/customer_medium.jpg    1x,
              @/assets/cant-find/customer_medium@2x.jpg 2x
            "
          />

          <source
            srcset="
              @/assets/cant-find/customer.webp    1x,
              @/assets/cant-find/customer@2x.webp 2x
            "
          />
          <source
            srcset="
              @/assets/cant-find/customer.jpg    1x,
              @/assets/cant-find/customer@2x.jpg 2x
            "
          />

          <img
            src="@/assets/cant-find/customer.jpg"
            alt="Customer section image"
            loading="lazy"
            width="1147"
            height="709"
          />
        </picture>
        <ul>
          <li>
            Another client wanted us to develop a bot which would automatically
            aid gamers by ‘watching’ the advertisements and collecting points
            for them while the player was AFK. It was a huge challenge, but we
            finally found a way and developed a cool bot which checked all the
            boxes.
          </li>
          <li>
            We worked with
            <a href="https://laspi.com/" target="_blank"><i>laspi.com</i></a>
            on their
            <a href="/portfolio/project/laspi">intranet</a>. The client trusted
            us and wanted us to further enhance their system - he wanted the
            system to predict the behavior of his site’s visitors. After the
            statistical data research, we came up with a few ideas, investigated
            them and finally developed an algorithm which allowed the client to
            view the sought-after predictions after the users had managed their
            way around his site.
          </li>
          <li>
            We have another client:
            <a href="https://fundraiserssports.com" target="_blank"
              ><i>https://fundraiserssports.com</i></a
            >, who makes engraved bricks for sports fans and then creates
            mosaics from them. They wanted us to create a virtual wall which
            would imitate the real wall with the original bricks, where fans
            would be able to find their brick while on their mobile phone… but
            we had nothing to start with - they didn’t even have a photo of the
            wall to show us. And how to deal with newly inserted bricks? To
            re-image the entire wall for each new brick wouldn’t be an option.
            Eventually the solution was simpler and more modern than we had
            anticipated: we prepared a drone that took a series of photos of the
            wall with GEO coordinates - after that, we were able to build an MVP
            on Unity to allow the client the opportunity to achieve the results
            he had wanted from the start.
          </li>
        </ul>
        <p>
          <em v-html="text"></em>
        </p>
        <ContactsButton size="large" :desc="text" :id="btnModalId" />
      </section-layout>

  </div>
</template>

<script>
import SectionLayout from "@/layouts/SectionLayout";
import ContactsButton from "@/components/ContactsButton.vue";

export default {
  data() {
    return {
      scroll: false,
      position: null,
      text: `
      Would you like to receive more examples of what we are able to help you achieve?<br />
      Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
      Would you like us to propose ways of turning your ideas into reality?
      `,
      btnModalId: 'cant-find-section-modal',
    };
  },
  components: {
    SectionLayout,
    ContactsButton,
  },
  methods: {
    back(){
      if(!document.referrer){
        Object.defineProperty(document,"referrer",{get : function(){ return `${window.location.origin}/blog` }})
        this.$router.push('/blog')
      }else{
        this.$router.go(-2)
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    },
  },
  created() {
    history.pushState('cant-find',window.location.href)
    window.history.scrollRestoration = "auto";
    window.addEventListener("scroll", this.scrolling);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },
};
</script>

<style lang="scss">
@import "../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";

.footer-blog-item{
  display:block;
  padding-top:20px;
  @include media(">=tablet") {
    display:flex;
    justify-content: space-between;
    align-items: center;
  }
  .cant-find-tags{
    margin-top:40px;
    color:$red;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    @include media(">=tablet") {
      margin-top:0px;
      text-align: center;
    }
    span{
      margin-right: 20px;
      @include media(">=widescreen") {
        margin-right: 40px;
      }
    }
  }
}


.cant-find-page {
  .btn-read-more{
  .button-content {
    border-radius: 26px;
    padding: 2px 12px 3px;
    background: $red;
    color: $white;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    cursor: pointer;
    width: max-content;
    background-image: linear-gradient($darkRed, $darkRed);
    background-repeat: no-repeat;
    transition: background-size 0s, color 0s;
    background-size: 0% 100%;
    @include media(">=tablet-xl") {
      padding: 6px 24px 8px;
    }
    &:hover {
      background-size: 100% 100%;
      color: $white;
    }
  }
  .icon {
    padding-top: 6px;
    margin-right: 15px;
    transform: rotateY(180deg);
  }
  a {
    color: $white;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  }

  .go-back-to-read {
    padding: 16px 10px;
    border-bottom-left-radius: 26px 26px;
    border-bottom-right-radius: 26px 26px;
    position: fixed;
    background: $white;
    z-index: 50;
    width: 100vw;
    transition: all 0.3s;
    @include media(">=tablet-xl") {
      padding: 16px 80px;
    }
    &.scrolling {
      padding: 5px 10px;
      border-bottom: 1px solid $red;
       @include media(">=tablet-xl") {
         padding: 5px 80px;
       }

    }
  }
}
.blog-cant-find {
  &.section_holder {
    display: flex;
    flex-direction: column-reverse;
    @include media(">=tablet-xl") {
      flex-direction: row;
    }
  }
  .section_title {
    margin-right: 0px;
    width: 100%;
    @include media(">=tablet-xl") {
      margin-right: 43px;
      width: 35%;
    }
  }
  .section_content {
    margin-bottom: 60px;
    display: block;
    margin-left: 0px;
    width: 100%;
    a {
      color: $red;
    }
    &__item{
      margin-bottom:40px;
      @include media(">=tablet") {
        margin-bottom:60px;
      }
      @include media(">=desktop") {
        margin-bottom:120px;
      }
    }
    @include media(">=tablet-xl") {
      margin-left: 37px;
      width: 65%;
      margin-bottom: 0px;
    }
  }
}

.cf__banner {
  height: 300px;
  position: relative;
  @include media(">=tablet") {
    height: 500px;
  }
  @include media(">=desktop") {
    height: 640px;
    margin-bottom: 60px;
  }
  &-img {
    display: block;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.cf__banner-title {
  text-transform: uppercase;
  color: $white;
  font-size: 32px;
  line-height: 111%;
  position: absolute;
  bottom: 20px;
  left: 16px;
  max-width: calc(100% - 32px);
  margin: 0;
  @include media(">=tablet") {
    bottom: 40px;
    font-size: 40px;
    left: 40px;
    max-width: calc(100% - 80px);
  }
  @include media(">=tablet-xl") {
    bottom: 80px;
    left: 40px;
    font-size: 56px;
    max-width: calc(100% - 80px);
  }
  @include media(">=widescreen") {
    bottom: 80px;
    left: 80px;
    font-size: 72px;
    max-width: 1150px;
  }
}
.cf__first,
.cf__innovations,
.cf__process,
.cf__customer {
  p {
    margin-bottom: 0;
  }
  ul {
    padding-left: 30px;
    margin-bottom: 0px;
    ul {
      margin-top: 32px;
      li {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    li {
      margin-bottom: 32px;
    }
  }

  a {
    color: $red;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .section_content {
    flex-direction: column;
    align-items: flex-start;
  }
  .section_title {
    margin-bottom: 30px;
    @include media(">=tablet") {
      margin-bottom: 60px;
    }
  }
}
.cf__img {
  margin: 40px 0;
  display: block;
}
.cf__customer {
  em {
    display: block;
    margin-top: 32px;
  }
  .button {
    margin-top: 40px;
  }
}
</style>
